/* Here so this will load considerably faster than the other
   stylesheet, and show the loading screen. */

html, body, .viewport, .veil {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	width: auto;
	height: auto;

	background-repeat: repeat;
	background-position: center center;

	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

	font-size: 13px;

	/* Next line breaks login window in Chrome. Is it needed? */
	/*-webkit-transform-style: preserve-3d;*/

	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-font-smoothing: antialiased;
}

.viewport, .veil {
	overflow: hidden;
}

input[type=text], input[type=password] {
	-webkit-border-radius: 0px;
}

.veil {
	/* Make sure the viel is above the viewport, toolbar and dialogs (.dialog), but below any modals (.notice). */
	z-index: 100001;

	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	transform: translateZ(0);
}

.veil .shadow {
	width: 100%;
	height: 100%;
	background: black;
	background: rgba(0,0,0,0.7);

	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	transform: translateZ(0);
}

a, a:visited {
	color: #FFFFFF;
	text-decoration: none;
	cursor: pointer;
}

a:hover {
	text-decoration: underline;
}

.logo {
  background-position: top center;
  background-repeat: no-repeat;
	height: 57px;
	width: 200px;
	padding-bottom: 15px;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.initialization-icon {
	width: 32px;
	height: 32px;
	background-position: center center;
	background-repeat: no-repeat;
	margin-left: auto;
	margin-right: auto;
}
